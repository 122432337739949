import i18n from '../../../locales/index'
export default {
  props: ['message', 'title', 'type', 'callback'],
  name: 'Alert',
  methods: {
    t (key) {
      return i18n.t(key)
    },
    cancel () {
      this.$bvModal.hide('alert-modal')
      if (this.callback) this.callback()
    }
  },
  computed: {
    classes () {
      const base = ['btn', 'width-button', 'margin-buttons']
      base.push(this.type && this.type === 'error' ? 'btn-danger' : 'btn-success')
      return base.join(' ')
    }
  },
  mounted () {
    this.$root.$on('bv::modal::hide', () => {
      this.$destroy() // cleanup in component
      this.$el.remove()
    })
  }
}
