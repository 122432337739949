import countriesEnUs from '@/locales/en-US/countries.json'
import countriesEsCl from '@/locales/es-CL/countries.json'

export default {
  name: 'CountriesTable',
  i18n: {
    messages: {
      'en-US': { countries: countriesEnUs },
      'es-CL': { countries: countriesEsCl }
    }
  },
  props: ['data', 'update', 'remove', 'view'],
  data () {
    return {
      perPage: 5,
      currentPage: 1,
      filterText: '',
      rows: this.data ? this.data.length : 0
    }
  },
  computed: {
    items () {
      return this.data.map(item => {
        return {
          id: item[0],
          name: item[1]
        }
      })
    },
    fields () {
      return [
        {
          key: 'id',
          label: this.$t('countries.table.columns.id'),
          sortable: true
        },
        {
          key: 'name',
          label: this.$t('countries.table.columns.name'),
          sortable: true
        },
        {
          key: 'actions',
          label: this.$t('countries.table.columns.actions'),
          sortable: true
        }
      ]
    }
  },
  methods: {
    onFiltered (filteredItems) {
      this.rows = filteredItems.length
    }
  }
}
