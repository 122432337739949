import Vue from 'vue'
import VueI18n from 'vue-i18n'
import commonEnUs from './en-US/common.json'
import commonEsCl from './es-CL/common.json'

Vue.use(VueI18n)

export default new VueI18n({
  locale: navigator.language,
  silentTranslationWarn: true,
  fallbackLocale: 'es-CL',
  messages: {
    'en-US': {
      common: commonEnUs
    },
    'es-CL': {
      common: commonEsCl
    }
  }
})
