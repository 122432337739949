import axios from 'axios'
import CountriesTable from '../Table/CountriesTable.vue'
import showFormCountries from '../Form/FormCountriesHandler'
import showAlert from '@/components/Utils/Alert/AlertHandler.js'
import countriesEnUs from '@/locales/en-US/countries.json'
import countriesEsCl from '@/locales/es-CL/countries.json'

export default {
  name: 'Countries',
  i18n: {
    messages: {
      'en-US': { countries: countriesEnUs },
      'es-CL': { countries: countriesEsCl }
    }
  },
  data () {
    return {
      items: []
    }
  },
  components: {
    CountriesTable
  },
  methods: {
    openViewForm (country) { showFormCountries(country, 'view') },
    openCreateForm () { showFormCountries(null, 'create', this.formCallback) },
    openUpdateForm (country) { showFormCountries(country, 'update', this.formCallback) },
    openDeleteForm (country) { showFormCountries(country, 'delete', this.formCallback) },
    formCallback (message) {
      showAlert(message.body, message.title, message.type)
      this.refresh()
    },
    async refresh () {
      const response = await axios.get('https://api-dev.waypoint.cl/demo/country')
      this.items = response.data.data
    }
  },
  mounted () {
    this.refresh()
  },
  computed: {
    translations () {
      return this.$i18n.messages
    }
  }
}
