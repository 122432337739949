import FormCountries from './FormCountries.vue'
import Vue from 'vue'

const showFormCountries = (country, operation, callback) => {
  const vm = new Vue({
    ...FormCountries,
    propsData: { country, operation, callback }
  })
  vm.$mount()
  vm.$bvModal.show('formCountries')
}
export default showFormCountries
