import axios from 'axios'
import i18n from '../../../locales/index'
import countriesEnUs from '@/locales/en-US/countries.json'
import countriesEsCl from '@/locales/es-CL/countries.json'

export default {
  name: 'FormCountries',
  props: ['country', 'operation', 'callback'],
  mounted () {
    i18n.setLocaleMessage('en-US', { ...i18n.messages['en-US'], countries: countriesEnUs })
    i18n.setLocaleMessage('es-CL', { ...i18n.messages['es-CL'], countries: countriesEsCl })
    this.$root.$on('bv::modal::hide', () => {
      this.$destroy()
      this.$el.remove()
    })
  },
  data () {
    return {
      form: {
        name: this.country ? this.country.name : ''
      },
      isBusy: false
    }
  },
  computed: {
    info () {
      if (this.operation === 'create') {
        return {
          title: this.t('countries.form.create.title'),
          btn: {
            label: this.t('common.buttons.create'),
            type: 'success'
          }
        }
      }
      if (this.operation === 'view') {
        return { title: this.t('countries.form.view.title') }
      }
      if (this.operation === 'delete') {
        return {
          title: this.t('countries.form.delete.title'),
          btn: {
            label: this.t('common.buttons.delete'),
            type: 'danger'
          }
        }
      }
      if (this.operation === 'update') {
        return {
          title: this.t('countries.form.update.title'),
          btn: {
            label: this.t('common.buttons.update'),
            type: 'warning'
          }
        }
      }
    }
  },
  methods: {
    t (key) {
      return i18n.t(key)
    },
    process () {
      if (!this.validate()) return false
      this.isBusy = true
      if (this.operation === 'create') this.create()
      if (this.operation === 'delete') this.delete()
      if (this.operation === 'update') this.update()
    },
    validate () {
      if (this.form.name.trim() === '') {
        this.$root.$emit('bv::show::popover', 'inputName')
        return false
      }
      return true
    },
    async create () {
      const response = await axios.post('https://api-dev.waypoint.cl/demo/country', this.form)
      if (response.status === 201) {
        this.callback({
          body: this.t('countries.alerts.success.create.message'),
          title: this.t('countries.alerts.success.create.title'),
          type: 'success'
        })
      } else {
        this.callback({
          body: this.t('countries.alerts.failure.create.message'),
          title: this.t('countries.alerts.failure.create.title'),
          type: 'error'
        })
      }
      this.$bvModal.hide('formCountries')
    },
    async update () {
      const response = await axios.patch('https://api-dev.waypoint.cl/demo/country/' + this.country.id, this.form)
      if (response.status === 200) {
        this.callback({
          body: this.t('countries.alerts.success.update.message'),
          title: this.t('countries.alerts.success.update.title'),
          type: 'success'
        })
      } else {
        this.callback({
          body: this.t('countries.alerts.failure.update.message'),
          title: this.t('countries.alerts.failure.update.title'),
          type: 'error'
        })
      }
      this.$bvModal.hide('formCountries')
    },
    async delete () {
      const response = await axios.delete('https://api-dev.waypoint.cl/demo/country/' + this.country.id)
      if (response.status === 200) {
        this.callback({
          body: this.t('countries.alerts.success.delete.message'),
          title: this.t('countries.alerts.success.delete.title'),
          type: 'success'
        })
      } else {
        this.callback({
          body: this.t('countries.alerts.failure.delete.message'),
          title: this.t('countries.alerts.failure.delete.title'),
          type: 'error'
        })
      }
      this.$bvModal.hide('formCountries')
    }
  }
}
