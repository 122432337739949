import Alert from './Alert.vue'
import Vue from 'vue'
const showAlert = (message, title, type, callback) => {
  const vm = new Vue({
    ...Alert,
    propsData: { message, title, type, callback }
  })
  vm.$mount()
  vm.$bvModal.show('alert-modal')
}
export default showAlert
