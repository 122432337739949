<template>
  <div id="app">
    <Header />
    <b-row id="body">
      <b-col lg="2">
        <Navigation />
      </b-col>
      <b-col lg="10">
         <router-view/>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import Header from '@/components/Layout/Header/Header.vue'
import Navigation from '@/components/Layout/Navigation/Navigation.vue'
export default {
  name: 'App',
  components: {
    Header,
    Navigation
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#body {
    margin-top: 30px;
}
#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
